/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                window.PageFunctions.smoothScroll();
                // JavaScript to be fired on all pages
                window.MenuFunctions.setupMenuToggle();
                window.MenuFunctions.setupSubMenuToggle();


            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired

                window.PageFunctions.lazyLoad();

                $('select').selectize({
                    create: false,
                });

                jQuery(document).bind('gform_post_render', function () {

                    $('.gform_body').find('select').selectize({
                        create: false,
                    });

                });

                $('.o-banner').on('change','.selectized',function(){
                    window.location.href = $(this).val();
                });


                $('.j-button-popup').on('click',function(){
                    var _this = $(this).parent();
                    $('body').addClass('no-scroll');
                    _this.find('.j-button-popup--container').removeClass('s-hidden');
                });
                $('.j-button-popup--close').on('click',function(){
                    $('body').removeClass('no-scroll');
                    $(this).parents('.j-button-popup--container').addClass('s-hidden');
                });


                $('.o-woo-popup--close').on('click',function(){
                    $(this).parents('.o-woo-popup').fadeOut();
                })
            }
        },
        // Home page
        'home': {
            init: function () {
                // JavaScript to be fired on the home page

            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
                window.blockFunctions.map_block();

            }
        },

        // Home page
        'page_block__locations': {
            init: function () {
                // JavaScript to be fired on the home page

            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
                window.blockFunctions.map_block();

            }
        },
        // Home page
        'page_block__video': {
            init: function () {
                // JavaScript to be fired on the home page

            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
                window.blockFunctions.video_block();

            }
        },
        // Home page
        'page_template_deals': {//
            init: function () {
                // JavaScript to be fired on the home page
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
                window.PageFunctions.dealFilter();

            }
        },
        'page_block__team': {//
            init: function () {
                // JavaScript to be fired on the home page
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS

                $slick_slider = $('.slider');
                settings = {
                    // some settings
                }
                $slick_slider.slick(settings);

                // reslick only if it's not slick()
                $(window).on('resize', function() {
                    if ($(window).width() < 768) {
                        if ($slick_slider.hasClass('slick-initialized')) {
                            $slick_slider.slick('unslick');
                        }
                        return
                    }

                    if (!$slick_slider.hasClass('slick-initialized')) {
                        return $slick_slider.slick(settings);
                    }
                });
                var carousel =  $('.j-team');
                var settings = {
                    arrows: true,
                    dots: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    mobileFirst: true,
                    responsive: [
                        {
                            breakpoint: 600,
                            settings: {
                                arrows: true,
                                slidesToShow: 2,
                                slidesToScroll: 2
                            }
                        }
                    ]
                };
                checkCarousel();
                // reslick only if it's not slick()
                $(window).on('resize', function() {
                  checkCarousel();
                });
                function checkCarousel(){
                    if ($(window).width() > 950) {
                        if (carousel.hasClass('slick-initialized')) {
                            carousel.slick('unslick');
                        }
                        return
                    }
                    if (!carousel.hasClass('slick-initialized')) {
                        return carousel.slick(settings);
                    }
                }
            }
        },

        // Home page
        'single_product': {
            init: function () {
                // JavaScript to be fired on the home page//
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
                var color_select = $('#pa_colour').selectize({
                    create: false,
                    placeholder: 'Select a Colour',
                    maxItems: 1
                });

                var size_select = $('#pa_size').selectize({
                    create: false,
                    placeholder: 'Select a Size',
                    maxItems: 1
                });
                $('.o-woo--container').on("click",'.reset_variations', function(){
                    var variation_form = $('.variations_form');

                    setTimeout(function(){//
                        console.log('22');

                        var color_control = $('#pa_colour')[0].selectize;
                        var size_control =  $('#pa_size')[0].selectize;


                        console.log(color_control);
                        console.log(size_control);


                        color_control.clear();

                        size_control.clear();


                        variation_form.find(".input-text.qty").val("1");//
                    }, 500);//
                });

                $('body').on('click','.pp_right',function(){
                    $('.pp_overlay').click();
                })
            }
        },



    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

