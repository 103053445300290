(function($, window, document) { // jQuery wrapper

    // Menu Functions
    window.MenuFunctions = {

        header: $('.o-header'),
        menu: $(".o-header--mobile"),

        // Toggle Main menu when menu toggle is clicked.
        setupMenuToggle : function (){
           this.header.on('click', '.j-menu--toggle', function () {
               window.MenuFunctions.menu.toggleClass('j-menu--open');
               $('body').toggleClass('no-scroll');
               $('body').toggleClass('cover-up');
               $(this).toggleClass('open');
            });


        },

        // Creates sub menu toggle fields, and controls their function
        setupSubMenuToggle : function(){
            this.menu.find('.sub-menu').siblings('a').after('<span class="menu-slide"></span>');
            this.menu.find('.menu-slide').on('click',function(e){
                $(this).toggleClass('open');
                e.preventDefault();
                e.stopPropagation();
                $(this).siblings('.sub-menu').stop(true, true).slideToggle(200);
            });
        }


    };

}(window.jQuery, window, document)); // End of jQuery wrapper

