(function($, window, document) { // jQuery wrapper

    // Page Functions
    window.PageFunctions = {

        menu: "",
        menuToggle: "",

        // Smooth scroll on page anchor links.
        // Will scroll to the top for # links.
        smoothScroll : function (){
            $('a[href^="#"]').click(function () {
                console.log('scrolling');
                var scrollTarget;
                // If the link is just # set scrollTarget to top of page
                if($(this).attr('href') === '#'){
                    scrollTarget = 0;
                }else{ // Otherwise locate the target element and find its offset from the top
                    if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
                        var target = $(this.hash);
                        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                        if (target.length) {
                            scrollTarget =  target.offset().top;
                        }
                    }
                }
                // Scroll the page to the target.
                $('html, body').animate({
                    scrollTop: scrollTarget
                }, 1000);
                return false; // Exit.
            });
        },
        lazyLoad: function(){
            $('.j-lazy').each(function(){
                $(this).attr('src',$(this).attr('data-src'));
            })
        },
        dealFilter: function(){

            $('.o-deal-index--filter-title').on('click',function(){
               $('.o-deal-index--filter-wrap').slideToggle();
            });
            $(window).on('load',function(){
                var index = $('.j-deals--index');
                var current = {
                    'location' : '*',
                    'category' : '*'
                }
                var filters = $('.j-deals--filters');

                index.isotope({
                    itemSelector: '.o-deal-index'
                });
                filters.on('click','.j-deals--filter',function(){
                    var _this = $(this);
                    var filter = _this.data('filter');
                    var type = _this.data('type');

                    if(_this.hasClass('active')){
                        _this.removeClass('active');
                        current[type] = "*";
                    }else{
                        _this.addClass('active').siblings().removeClass('active');
                        current[type] = filter;
                    }

                    index.isotope({filter: current.location  + current.category });
                    if ( !index.data('isotope').filteredItems.length ) {
                        index.parent().addClass('no-results');
                    }else{
                        index.parent().removeClass('no-results');
                    }
                });
                $('.j-deals--select').on('change',function(){
                    var _this = $(this);
                    var filter = _this.val();
                    current['location'] = filter;

                    $('.j-deals--filter[data-filter="' + filter + '"]').addClass('active').siblings().removeClass('active');



                    index.isotope({filter: current.location  + current.category });
                    if ( !index.data('isotope').filteredItems.length ) {
                        index.parent().addClass('no-results');
                    }else{
                        index.parent().removeClass('no-results');
                    }

                });
            })
        }

    };

}(window.jQuery, window, document)); // End of jQuery wrapper

